
import DNABI from "./DivineNymphsMinter.json";
import { nftAddress } from "./config.js";

const Web3 = require("web3");

let web3Metamask;
let metamaskAccounts;
let metamaskAccount;

//--- WEB3 Connection ---
export const web3Connect = async () => {
  if (typeof window !== "undefined") {
    console.log("if window");
    await window.ethereum.request({ method: "eth_requestAccounts" });
    web3Metamask = new Web3(window.ethereum);

    metamaskAccounts = await web3Metamask.eth.getAccounts();
    metamaskAccount = metamaskAccounts[0];

    console.log(metamaskAccount)
  }
};

//--- ADMIN ---//

export const mintOnOff = async () => {
  try {
    await web3Connect();
  } catch (err) {
    console.log("Error connecting to metamask", err);
  }

  const minterContract = await new web3Metamask.eth.Contract(
    DNABI.abi,
    nftAddress
  );

    let transaction = await minterContract.methods.mintOnOff().send({
    from: metamaskAccount,
    });

    return transaction;
  
};

export const royaltyOnOff = async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
      let transaction = await minterContract.methods.royaltyOnOff().send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const discountOnOff = async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
      let transaction = await minterContract.methods.discountOnOff().send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const deleteDiscount = async (_coupon) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
      let transaction = await minterContract.methods.deleteDiscount(_coupon).send({
      from: metamaskAccount,
      });

    return transaction;

  };

  export const _setBaseURI = async (_newBaseURI) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );

      let transaction = await minterContract.methods._setBaseURI().send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const withdrawBalance = async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );

      let transaction = await minterContract.methods.withdrawBalance().send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const mintDNAdmin = async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
  
    let transaction = await minterContract.methods.mintDNAdmin().send({
      from: metamaskAccount
    });

    let tokenId = await transaction.events.Transfer.returnValues[2];
    if (tokenId) {
      return tokenId;
    } else {
      return null;
    }
  };

  export const mintDNBatchAdmin = async (numberMints) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
  
    let transaction = await minterContract.methods.mintDNBatchAdmin(numberMints).send({
      from: metamaskAccount
    });
  };

  


  export const mintToAddress = async (_to) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
  
    let transaction = await minterContract.methods.mintToAddress(_to).send({
      from: metamaskAccount
    });
    
    let tokenId = await transaction.events.Transfer.returnValues[2];
    if (tokenId) {
      return tokenId;
    } else {
      return null;
    }
  };

  export const mintToAddressBatch = async (addresses) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
  
    let transaction = await minterContract.methods.mintToAddressBatch(addresses).send({
      from: metamaskAccount
    });
  };


  export const setDiscount = async (_coupon, _discount) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
      let transaction = await minterContract.methods.setDiscount(_coupon, _discount).send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const setDiscountAddressAdmin = async (_discountAddress, _coupon) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
      let transaction = await minterContract.methods.setDiscountAddressAdmin(_discountAddress, _coupon).send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const setListingPrice = async (_newListingPrice) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );

      await minterContract.methods.setListingPrice(_newListingPrice).send({
      from: metamaskAccount,
      });

    let newListingPrice = await minterContract.methods.getListingPrice().call({
        from: metamaskAccount,
        });

    return newListingPrice;

  };


  export const setDivineNymphsWallet = async (_newdivineNymphsWallet) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
      let transaction = await minterContract.methods.setDivineNymphsWallet(_newdivineNymphsWallet).send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const _setTokenRoyaltyAdmin = async (_tokenID,  _royaltyReceiver, _royaltyValue) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
      let transaction = await minterContract.methods._setTokenRoyaltyAdmin(_tokenID,  _royaltyReceiver, _royaltyValue).send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const withdrawPayments = async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );

      let transaction = await minterContract.methods.withdrawPayments().send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const transferContractBalance = async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );

      let transaction = await minterContract.methods.transferContractBalance().send({
      from: metamaskAccount,
      });

    return transaction;

  };


  //---USER / CLIENT---//

  export const getListingPrice = async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );

      let listingPrice = await minterContract.methods.getListingPrice().call({
      from: metamaskAccount,
      });

    return listingPrice;

  };

  export const getPriceDiscount = async (_couponName) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );

    let priceDiscount = await minterContract.methods.getPriceDiscount(_couponName).call({
      from: metamaskAccount,
      });

    return priceDiscount;

  };

  export const mintDN = async (_collection_price) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
  
    let transaction = await minterContract.methods.mintDN().send({
      from: metamaskAccount,
      value: _collection_price
    });

    let tokenId = await transaction.events.Transfer.returnValues[2];
    if (tokenId) {
      return tokenId;
    } else {
      return null;
    }
  };

  export const mintDNBatch = async (_numberMints, _value) => {
    console.log(_numberMints)
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
  
    let transaction = await minterContract.methods.mintDNBatch(_numberMints).send({
      from: metamaskAccount,
      value: _value
    });

    let eventsId = await transaction.events.Transfer.returnValues[2];
    if (eventsId) {
      return eventsId;
    } else {
      return null;
    }
  };


  export const setDiscountAddress = async (_coupon) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
      let transaction = await minterContract.methods.setDiscountAddress(_coupon).send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const _setTokenRoyalty = async (_tokenID, _royaltyValue) => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );
      let transaction = await minterContract.methods._setTokenRoyalty(_tokenID, _royaltyValue).send({
      from: metamaskAccount,
      });

    return transaction;

  };


  export const haveDiscount = async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  
    const minterContract = await new web3Metamask.eth.Contract(
      DNABI.abi,
      nftAddress
    );

      let theDiscount = await minterContract.methods.hasDiscount(metamaskAccount).call({
      from: metamaskAccount,
      });

    return theDiscount;

  };