import React from "react";

// STYLES
import styled from "styled-components";

const NotFoundStyles = styled.section`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-family: 'alagard';
  font-size: 3rem;
`

export default function NotFound () { 
  return (
    <NotFoundStyles>
      <h1>{"404 Not Found"}</h1>
    </NotFoundStyles>
  )
}