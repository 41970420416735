import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { 
  web3Connect, 
  mintDN, 
  mintDNBatch,
  getListingPrice, 
  haveDiscount, 
  setDiscountAddress, 
  getPriceDiscount, 
} from '../services/dn-web3-functions'

// REDUX
import { fetchData } from "../redux/data/dataActions";

// VIEWS
import Connect from './Connect';

// COMPONENTS
import Background from '../components/Background';
import IconBar from  '../components/IconBar';

// STYLES
import styled from "styled-components";

// WEB3
let Web3 = require('web3');

const MintStyles = styled.section`
  font-family: 'alagard';
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* FEEDBACK TEXT */
  .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0 0 0;
    color: black;
    font-size: 1.3rem;
  }
  .header, .feedback {
    z-index: 50;
  }
  /* MINTING PANEL */
  .mint-panel-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    height: 145px;
    margin-top: 225px;
  }
  .mint-panel {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    margin-bottom: 60px;
    z-index: 10; 
    align-items: center;
    justify-content: center;
  }
  .button-panel-img {
    position: absolute;
    height: auto;
    left: 0;
    right: 0;
    z-index: 5;
    margin: auto;
  }
  .button-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 550px; 
    height: auto;
    margin: 0px 0;
    z-index: 10;
  }
  .mint-buttons {
    position: relative;
    display: flex;
    padding-top: 10px;
    z-index: 50;
  }
  button.mint-number-button, button.mint-nft-button {
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-top: 2px solid silver;
    border-left: 2px solid silver;
  }
  button.mint-number-button, button.mint-nft-button:hover {
    cursor: pointer;
  }
  button.mint-number-button {
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    z-index: 10;
  }
  button.mint-nft-button {
    font-size: 1rem;
    padding: 0 10px;
  }
 form.coupon{
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 50;
 }
 form.coupon-panel button {
  margin: 10px;
  padding: 5px;
  background: #efefef;
 }
 .windows95 {
  background: #cac8c1;
  color: black;
  border-top: 3px solid #f5f6f0;
  border-left: 3px solid #f5f6f0;
  border-right: 3px solid#5f5e5d;
  border-bottom: 3px solid #5f5e5d;
 }
 .coupon-panel {
  width: 300px;
  height: 150px;
  display: flex;
  padding: 3px 0 15px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 }
 .coupon-panel.have-coupon:hover {
  background: lightgray;
  cursor: pointer;
 }
 .coupon-panel h2 {
  padding-top: 10px;
  color: black;
 }
 .coupon-panel form label {
  color: black;
  padding-bottom: 5px;
 }
 form.coupon button {
  margin: 10px;
  padding: 5px;
  background: #efefef;
 }
 form.coupon button:hover {
  background: #e1dfdf;
  cursor: pointer;
 }
 /* X-BUTTON */
 .x-button-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-end;
 }
 .x-button {
  width: 20px;
  height: 20px;
  line-height: 0;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
 }
 .x-button:hover {
  background: #efefef;
 }
 

  /* ANIMATION */
  @-webkit-keyframes pulse-box {
  0% {-webkit-box-shadow: 0px 0px 10px 0px rgba(242, 255, 0, 0.6);}
  100% {-webkit-box-shadow: 1px 1px 18px 1px rgba(251, 255, 0, 0.9);}
  }
  @-moz-keyframes pulse-box {
    0% {-moz-box-shadow: 0px 0px 10px 0px rgba(246, 255, 0, 0.6);}
    100% {-moz-box-shadow: 1px 1px 18px 1px rgba(255, 247, 0, 0.9);}
  }
  @-o-keyframes pulse-box {
    0% {-o-box-shadow: 0px 0px 10px 0px rgba(255, 242, 0, 0.6);}
    100% {-o-box-shadow: 1px 1px 18px 1px rgba(255, 251, 0, 0.9);}
  }
  @keyframes pulse-box {
    0% {box-shadow: 0px 0px 10px 0px rgba(255, 247, 0, 0.6);}
    100% {box-shadow: 1px 1px 18px 1px rgba(255, 251, 0, 0.9);}
  }

  .pulse-box:hover {
    -webkit-animation-name: pulseBox;
    -moz-animation-name: pulseBox;
    -o-animation-name: pulseBox;
    animation-name: pulseBox;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    -moz-animation-direction: alternate;
    -o-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    border: 0px;
  }


  /* MOBILE */
  @media screen and (max-width: 896px) {
    .header-wrapper {
      margin-top: 25px;
    }
    .header, .feedback {
      font-size: .8rem;
    }
    .mint-panel-wrapper {
      width: 90%;
    }
    .connect-button-panel {
      width: 90%;
    }
    .button-wrapper {
      width: 100%
    }
    .button-panel-img {
      width: 90%;
      height: auto;
    }
  }
`

export default function MintComponent () {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [feedback, setfeedback] = useState();
  const [claimingNFT, setClaimingNFT] = useState(false);
  const [mintNumber, setMintNumber] = useState(1);
  const [isCouponInput, setIsCouponInput] = useState(false);

  let metamaskAccount;

  const updateAddress = useCallback(async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  }, [])

  useEffect(() => {
    updateAddress();
  }, [updateAddress])

  const getNewMintNumber = (newMintNumber, opperation) => {
    console.log({ newMintNumber })

    if (newMintNumber > 0 && newMintNumber < 11 && opperation === '+'){
      setMintNumber(mintNumber + 1)
    } else if (newMintNumber > 1 && opperation === '-') {
      setMintNumber(mintNumber - 1)
    } else {
      return null
    }
  }

  const getUserCoupon = async(_coupon) => {
    let coupName = document.getElementById('f').value;
    let coupAddress = metamaskAccount;
    setDiscountAddress(coupName)

  };

  const completeSales = async()=> {
    const mrDiscount = await haveDiscount()
    console.log('coupon name', mrDiscount)  
    if(mrDiscount === "nodiscount") {
      batchClaim()
      console.log("Does not have discount!")
    }
    else {
      batchClaimDiscount(mrDiscount)
    }
  };

  const batchClaim = async() => {
    let listingprice = await getListingPrice()
    let totalCostarino = mintNumber * listingprice

    if (mintNumber === 1) {
      mintDN(totalCostarino)
    } else if (mintNumber > 1 && mintNumber < 12) {
      mintDNBatch(mintNumber - 1, totalCostarino)
    }
  };

  const batchClaimDiscount = async(_couponName) => {
    let discountprice = await getPriceDiscount(_couponName)
    console.log(discountprice)
    let totalCostarino = mintNumber * discountprice
    console.log(_couponName)
    console.log(mintNumber)

    if (mintNumber === 1) {
      mintDN(totalCostarino)
    } else if (mintNumber > 1 && mintNumber < 12) {
      mintDNBatch(mintNumber - 1, totalCostarino)
    }
  };

  const claimNFTwithDiscount = async(_couponName) => {
    const discountTime = await getPriceDiscount(_couponName)
    mintDN(discountTime)
    console.log("You can claim an NFT with the discount baybeeeee")
  };

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, dispatch, blockchain.account, updateAddress]);

  useEffect(() => {
    updateAddress();
  }, [updateAddress])

  // console.log(metamaskAccountRef.current)
  // let web3 = new Web3(window.ethereum)
  // console.log(web3)

  console.log({ mintNumber })

  return (
    <>
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <Connect />
      ) : (
        <MintStyles id="mint-page-wrapper">  
          <article className="mint-panel-wrapper">
            <div className="mint-panel">
              <div className="header-wrapper">
                <h2 className="header">Do you dare open Pandora's Box?</h2>
                <h3 className="feedback">{feedback}</h3>
              </div>

              <div className="button-wrapper">
                <div className="mint-buttons">
                  <button className="mint-number-button" onClick={(e) => getNewMintNumber(mintNumber, '-')}> - </button>
                  <button className="mint-nft-button pulse-box"
                    disabled={claimingNFT ? 1 : 0}
                    onClick={(e) => {
                    e.preventDefault();
                    completeSales();
                  }}>
                    {claimingNFT ? "MINTING NYMPH" : `SUMMON ${mintNumber} DIVINE NYMPH${mintNumber > 1 ? 'S' : ''}`} 
                  </button>
                  <button className="mint-number-button" onClick={(e) => getNewMintNumber(mintNumber, '+')}> + </button>
                </div>
              </div>
              <img className="button-panel-img" alt="button-panel" src="../../assets/dvn_notallow_popup.png" />
            </div>



            {isCouponInput === true ? (
                <div className="coupon-panel windows95">
                  <form className="coupon">
                    {isCouponInput === true ? 
                        <div className="x-button-wrapper">
                          <span 
                            className="x-button windows95" 
                            onClick={(e) => {
                              e.preventDefault();
                              setIsCouponInput(false);
                          }}>{"X"}</span> 
                        </div>
                      : null}
                    <label>Claim your coupon</label>
                    <input type="text" name="couponname" id="f" placeholder="coupon name"></input>

                    <button
                      className="windows95"
                      onClick={(e) => {
                        e.preventDefault();
                        getUserCoupon();
                      }}
                      >
                        {" VALIDATE COUPON "} 
                    </button>
                  </form>
                </div>
              ) : (
                <div className="coupon-panel windows95 have-coupon">
                  <h2 onClick={(e) => {
                    e.preventDefault();
                    setIsCouponInput(true);
                  }}>
                    {"Have a Coupon?"}
                  </h2>
                </div>
            )}
          </article>
        </MintStyles>
      )} 

      <IconBar />
      <Background />
    </>
  

  )
}