import React from "react";

// STYLES
import styled from "styled-components";

// ICONS
import { FaGithub } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { FaDiscord } from "react-icons/fa";
import { SiNotion } from "react-icons/si";
import { Link } from 'react-router-dom';

// ASSETS

const IconBarStyles = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 150px;
  z-index: 5;

  /* ICON BAR */
  .icon-bar-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
  }
  .icon-bar-wrapper {
    margin-top: 50px;
    justify-content: space-around;
  }
  .icon-bar a .dvn-socials {
    color: white;
    fill: white;
    margin: 0 5px;
  }


  /* ANIMATION */
  .floating { 
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      margin-top: 5px;
  }
  
  @keyframes floating {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 5px); }
      100%   { transform: translate(0, -0px); }   
  }
`

export default function IconBar () {
  return (
    <IconBarStyles id="icon-bar-wrapper"> 
    
      <div className="icon-bar">
        <article className="icon-bar-wrapper">
          <span className="icon-bar">
          
            {/* <a href="https://github.com/0xMarsyas" target="_blank" rel="noreferrer">
            <FaGithub  className="dvn-socials" size="3em" />
            </a> */}
    
            <a href="https://twitter.com/DivineNymphs" target="_blank" rel="noreferrer">
              <FiTwitter className="dvn-socials" size="3em"/>
            </a>
    
            <a href="https://discord.com/invite/zvXUU6ebs9" target="_blank" rel="noreferrer">
              <FaDiscord rel="norefferer" className="dvn-socials" size="3em" />
            </a>
    
            <a href="https://steadfast-roarer-21c.notion.site/Divine-Nymphs-Wiki-9580808515c94431b958aec98fa6b5c2" target="_blank">
              <SiNotion className="dvn-socials" size="3em" />
            </a>

          </span> 
        </article>


      </div>


    </IconBarStyles>
  )
}