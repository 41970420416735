import React from "react";
// { userState } 

// ROUTING
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRoutes'
// Navigate
// import { createMemoryHistory } from 'history';


// COMPONENTS
import Admin from './views/Admin'
import Mint from './views/Mint'
import NotFound from './views/NotFound'

// STYLES
import "./App.css";

// const ProtectedRoute = ({ user, redirectPath = '/', children }) => {
//   if (!user) {
//     return <Navigate to={redirectPath} replace />
//   }

//   return children;
// }

// { user }
const App = () => {
  // const [user, setUser] = useState(null);

  // const handleLogin = () =>
  //   setUser({
  //     id: '1',
  //     name: 'robin',
  //     permissions: ['analyze'],
  //     roles: ['admin'],
  //   });

  // const handleLogout = () => setUser(null);

  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes/>}>
          <Route path="/kenbone" element={<Admin/>} />
        </Route>
        <Route path="/" element={<Mint />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;