import React, { useEffect, useCallback } from "react";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";

// WEB3
import { web3Connect, mintDN, mintDNBatch, getListingPrice, haveDiscount, setDiscountAddress, transferContractBalance, getPriceDiscount, _setBaseURI, _setTokenRoyaltyAdmin, _setTokenRoyalty, setListingPrice, setDivineNymphsWallet, setDiscount, deleteDiscount, setDiscountAddressAdmin, mintOnOff, mintDNAdmin, royaltyOnOff, mintToAddress, withdrawBalance, discountOnOff } from '../services/dn-web3-functions'

// STYLES
import styled from "styled-components";

const ConnectPageStyles = styled.section`
  z-index: 30;
  font-family: 'alagard';
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
  .dvn-title {
    z-index: 20; 
    display: flex;
    justify-content: center;flex: 1; 
    max-height: 200px; 
    align-items: center;
  }
  /* CONNECT BUTTON PANEL */
  .connect-button-panel-wrapper {
    position: relative;
    display: flex; 
    flex-direction: column;
    max-height: 250px; 
    align-items: center;
    justify-content: center;
  }
  .connect-button-panel {
    position: absolute;
    width: 550px; 
    margin: 60px auto 0 auto;
    z-index: 10;
  }
  .connect-button-wrapper {
    z-index: 15;
    width: 122px;
    display: flex;
    position: absolute;
    flex: 1;
    left: 0;
    right: 0;
    height: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }
  .connect-button-panel-img {

  }
  /* CONNECT BUTTON */
  .connect-button {
    display: flex;
    z-index: 15;
  }
  .connect-text {
    color: blue;
  }
  .connect-button-img {
    position: absolute;
  }

  @media screen and (max-width: 896px) {
    .connect-button-panel-wrapper {
      width: 100%;
      height: auto;
      margin-top: 60px;
    }
    .connect-button-panel {
      width: 90%;
      margin: auto;
    }
    .connect-button-panel-img {
      width: 100%;
      height: auto;
    }
  }
`


export default function ConnectPage () {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const updateAddress = useCallback(async () => {
    try {
      await web3Connect();
    } catch (err) {
      console.log("Error connecting to metamask", err);
    }
  }, [])

  useEffect(() => {
    updateAddress();
  }, [updateAddress])

  // if (window.ethereum) {
  //   const web3Instance = new Web3(window['ethereum']);

  //   var methods = {
  //     getAddressETHBalance: function (address) {

  //       return new Promise(function(resolve, reject) {
  //         resolve(web3Instance.eth.getBalance(address));
  //       })
  //     }
  //   }

  //   methods.getAddressETHBalance(useAddress)

  // } else {
  //   alert('Missing MetaMask extention.');
  // }

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, dispatch, blockchain.account]);
  
  return (
    <ConnectPageStyles id="connect-page">
      <h3>Connect to the Blockchain</h3>
      
      <div className="connect-button-panel-wrapper">
        <article className="connect-button-panel">
          <div className="connect-button-wrapper">
            <span
              className="connect-button"
              onClick={(e) => {
                e.preventDefault();
                // dispatch(connect());
              }}
            >
              <span className="connect-text">MINT PAUSED 
          </span>
            </span>
            <img alt="connect-button-img" className="connect-button-img" src="../assets/dvn_notallow_button.png" />
          </div>
          <img alt="button-panel" className="connect-button-panel-img" src="../assets/dvn_notallow_popup.png" />
        </article>

      </div>
      {blockchain.errorMsg !== "" ? <h3>{blockchain.errorMsg}</h3> : null}
    </ConnectPageStyles>
  )
}