import React from "react";

// STYLES
import styled from "styled-components";

// ASSETS

const BackgroundStyles = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;

  /* TITLE */
  .dvn-title {
    margin-top: 100px;
    z-index: 150;
  }
  .dvn-title img {
    height: 75%;
  }
  /* SMILEYS */
  .smileys-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    position: relative;
    z-index: 10;
  }
  .smiley {
    display: inline-block;
    position: relative;
    height: auto;
  }
  .smiley img {
    display: inline-block;
    position: relative;
    height: 100%;
  }
  /* NYMPH ENTRANCE */
  .nymph-entrance {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    height: 100%;
  }
  /* ARCHWAY */
  .archway-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    height: 100%;
    top: 0;
  }
  .archway-wrapper img {
    max-height: 60%
  }
  /* MEDUSSA */
  .medussa-wrapper {
    position: relative;
    z-index: 15;
    margin-bottom: 150px;
  }
  .medussa-img {
    height: 80%;
  }
  /* BACKGROUND */
  .background {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .background-water {
      width: 100vw;
      height: 100vh;
  }
  .background-water img {
    width: 190%;
  }
  video.background-video {
    position: absolute;
    max-height: 800px;
    width: 150%;
    bottom: 0;
    left: 0;
    right: 0;
    /* border: 10px solid red; */
  }
  
  /* ANIMATION */
  .floating { 
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      margin-top: 5px;
  }
  
  @keyframes floating {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 5px); }
      100%   { transform: translate(0, -0px); }   
  }

  /* MOBILE */
  @media screen and (max-width: 896px) {
    /* TITLE */
    .dvn-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dvn-title img {
      width: 90%;
      height: auto;
    }
    /* SMILEYS */
    .smileys-wrapper {
      margin-top: 20px;
      justify-content: space-around;
      width: 90%;
    }
    .smiley img {
      height: 100px;
    }
    /* ARCHWAY */
    .archway-wrapper img {
      width: 90%;
      height: auto;
    }
    /* MEDUSSA */
    .medussa-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 15;
      margin-bottom: 100px;
    }
    .medussa-img {
      width: 60%;
      height: auto;
    }
    /* BACKGROUND */
    .background {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

export default function Background () {
  return (
    <BackgroundStyles id="background-wrapper"> 
      <div className="background">

        <article className="dvn-title">
          <img alt="title" src="./assets/dvn_title.png" />
        </article>

        <article className="smileys-wrapper floating">
          <span className="smiley">
            <img alt="smiley" src="../assets/dvn_happy_face.png"/>
          </span> 
          <span className="smiley">
            <img alt="smiley" src="../assets/dvn_happy_face.png"/>
          </span> 
        </article>

        <article className="nymph-entrance">
          <div className="archway-wrapper">
            <img alt="arch" src="../assets/dvn_archway.png" />
          </div>

          <div className="medussa-wrapper floating">
            <img alt="medussa" className="medussa-img" src="../assets/dvn_medusa.png" />
          </div>
        </article>

        <article className="background-water">
          
          <video className="background-video" autoPlay={true} muted loop width="auto">
            <source src="https://res.cloudinary.com/react-graphql-store/video/upload/v1667680346/DVN/dvn_bg_water_wy0ge2.webm" type="video/webm" />
            <source src="https://res.cloudinary.com/react-graphql-store/video/upload/v1667680346/DVN/dvn_bg_water_lwcsxr.mp4" type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video>
          {/* <img alt="background" slrc="../assets/dvn_water.png" /> */}
        </article>

      </div>



    </BackgroundStyles>
  )
}