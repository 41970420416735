import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// REDUX
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";

// WEB3
import { 
  transferContractBalance, 
  mintToAddressBatch, 
  mintDNBatchAdmin, 
  setDiscountAddress, 
  _setBaseURI, 
  _setTokenRoyaltyAdmin, 
  setListingPrice, 
  setDivineNymphsWallet, 
  setDiscount, 
  deleteDiscount, 
  mintOnOff, 
  mintDNAdmin, 
  royaltyOnOff, 
  mintToAddress, 
  withdrawBalance, 
  discountOnOff,
  setDiscountAddressAdmin, 
  _setTokenRoyalty, 
  getPriceDiscount, 
} from '../services/dn-web3-functions'


// STYLES
import "../App.css";
import styled from "styled-components";


// ASSETS
import adminzone from '../funny/wire.png'
import adminzone2 from '../funny/wire2.png'
import adminzone3 from '../funny/wire3.png'
import security from '../funny/stare-sam-jackson.gif'

const AdminStyles = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    margin-top: 50px;
    font-size: 2rem;
  }

  h3 {
    margin: 10px 0;
    font-size: 1.5rem;
  }

  button {
    margin: 20px 0;
    padding: 5px;
  }

  form {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 25px;
    border: 2px solid white;
    padding: 30px;
    border-radius: 24px;
  }  
  form label {
    padding-bottom: 5px;
    font-weight: bold;
  }
  form div {
    display: flex;
    flex-direction: column;
  }
  /* MINT BATCH ADDRESS */
  .mint-batch-address div {
    display: flex;
    flex-direction: column;
  }
  .mint-batch-address {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 25px;
    border: 2px solid white;
    padding: 30px;
    border-radius: 24px;
  }
  .mint-batch-address > div:first-child, .mint-batch-address > div:nth-child(2) {
    padding: 30px 0;
  }
  .mint-batch-address div:nth-child(2) {
    
  }
  .mint-batch-address div.mint-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .batch-address-array-string {
    inline-size: 100%;
    overflow-wrap: break-word;
  }
  .batch-address-array-string div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .batch-address-array-string span:first-child {
    font-weight: 600;
    margin-right: 10px;
  }
`

export default function AdminComponent () {

  const data = useSelector((state) => state.data);
  const [AdminsOpping, setAdminsOpping] = useState(false);
  const [claimingNFT, setClaimingNFT] = useState(false);
  const [mintNumber, setMintNumber] = useState(1);
  const [batchMintArray, setBatchMintArray] = useState([]);
  const [batchAddress, setBatchAddress] = useState('');
  const [batchAddressArray, setBatchAddressArray] = useState([]);

  const giveaCoupon = async(_discountAddress, _coupon) => {
    let coupName = document.getElementById('f').value;
    let coupAddress = document.getElementById('g').value;
    setDiscountAddress(coupAddress, coupName)

  };

  
  const givenewbaseURI = async (_newBaseURI) => {
    let aceofbase = document.getElementById('nbz').value;
    console.log(aceofbase)
    _setBaseURI(aceofbase)
  }





const newListingPrice = async(_newListingPrice) => {
  let newprice = document.getElementById('np').value;
  setListingPrice(newprice)
}


  const handleBatchAddressChange = event => {
    setBatchAddress(event.target.value);
  }

  const MintSwitch = async() => {
    mintOnOff()
  }
  const getNewMintNumber = (newMintNumber, opperation) => {
    console.log(newMintNumber)

    if (newMintNumber > 0 && newMintNumber < 11 && opperation === '+'){
      setMintNumber(mintNumber + 1)
    } else if (newMintNumber > 1 && opperation === '-') {
      setMintNumber(mintNumber - 1)
    } else {
      return null
    }
  }

  const newDiscount = async(_coupon, _discount) => {
    let NewCoupPerc = document.getElementById('6').value;
    let NewCoupName = document.getElementById('9').value;
    setDiscount(NewCoupName, NewCoupPerc)
  }

  const NewWallet = async(_newdivineNymphsWallet) => {
    let newbank = document.getElementById('nw').value;
    console.log(newbank)
    setDivineNymphsWallet(newbank)
  }

  const RoyaltySwitch = async() => {
    royaltyOnOff()
  };


const DiscountBomb = async(_amount) => {
  let Discount2Chop = document.getElementById('x').value;
  deleteDiscount(Discount2Chop)
}


const RoyaltyChange = async(_tokenID,  _royaltyReceiver, _royaltyValue) => {
  let NewwRoyalty = document.getElementById('nr').value
  let TokenID = document.getElementById('tid').value
  let RoyalReceiver = document.getElementById('rr').value
  console.log(TokenID, RoyalReceiver, NewwRoyalty)
  _setTokenRoyaltyAdmin(TokenID, RoyalReceiver, NewwRoyalty)
};


const NewBaseURI = async(_newBaseURI) => {
let basebase = document.getElementById('nb').value
    console.log('nb')
};


  const DiscountSwitch = async() => {
    discountOnOff()
  };

  const PullItAll = async() => {
    
    withdrawBalance()
  };

  const AdminMint = async() => {
    mintDNAdmin()
  }

  const RipItBaby = async() => {
    transferContractBalance()
  }

  const mintToAdminBatch = async(NumberMints) => {
    mintDNBatchAdmin(mintNumber)
  }

  const getMintToAddressBatch = async(addresses) => {
    setBatchAddressArray([]);

    for(let i = 0; i < mintNumber; i++) {
      setBatchAddressArray((prevArray) => [...prevArray, batchAddress])
    }

    mintToAddressBatch(batchAddressArray);
  }

  const mintToAddressAdmin = async(_to) => {
    mintToAddress(document.getElementById('a').value)
  }
  
  console.log(batchAddressArray);
  
  return (
    <AdminStyles>
      <h2>Ken Bone's ADMIN ONLY Zone</h2>
      
      <button
        disabled={AdminsOpping ? 1 : 0}
        onClick={(e) => {
          e.preventDefault();
          AdminMint();
        }}
      >
        {AdminsOpping ? "HANG ON " : "MINT A FREE NYMPH ON THE HOUSE (ONLY THE ADMIN)"} 
      </button>


      <div className="mint-buttons">
        <button className="mint-number-button" onClick={(e) => getNewMintNumber(mintNumber, '-')}> - </button>
        <button className="mint-nft-button pulse-box"
          disabled={claimingNFT ? 1 : 0}
          onClick={(e) => {
          e.preventDefault();
          mintToAdminBatch(mintNumber);
        }}>
          {claimingNFT ? "MINTING NYMPH" : `SUMMON ${mintNumber} DIVINE NYMPH${mintNumber > 1 ? 'S' : ''} TO THE ADMIN WALLET`} 
        </button>
        <button className="mint-number-button" onClick={(e) => getNewMintNumber(mintNumber, '+')}> + </button>
      </div>


      <form>
        <label>mint ONE SINGLE NYMPH _to an address ADMIN</label>
        <input type="text" name="minttoaddy" id="a" placeholder="&nbsp _to (address)"></input>
        <button
          disabled={AdminsOpping ? 1 : 0}
          onClick={(e) => {
          e.preventDefault();
            mintToAddressAdmin("a");
          }}
        >
          {AdminsOpping ? "MM OK  IF YOU SAY SO!" :  " MINT TO THAT ADDRESS ABOVE "} 
        </button>
      </form>

    <div className="mint-batch-address">
      <h3>Set Batch Address</h3>

        <div>
          <input
            type="text"
            name="address"
            placeholder="BATCH ADDRESS"
            onChange={handleBatchAddressChange}
            value={batchAddress}
            />
          <span>{`CURRENT-BATCH-ADDRESS: ${batchAddress}`}</span>
        </div>

        <hr />

        <div>
          <h3 className="batch-mint-array-text">Batch ARRAY: {batchMintArray}</h3>
          <button className="mint-nft-button" disabled={batchAddress === "" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                getMintToAddressBatch();
              }}>
              {`SET BATCH ADDRESS ARRAY`} 
          </button>

          (batchAddressArray)
          <span className="batch-address-array-string">
            {batchAddressArray.map((val, index) => {
              return (
              <div key={index}>
                <span>{index + 1}</span>
                <span>{val}</span>
              </div>)
            })}
          </span>
        </div>
      

      <div className="mint-buttons">
        <button className="mint-number-button" onClick={(e) => getNewMintNumber(mintNumber, '-')}> - </button>
        <button className="mint-nft-button pulse-box"
            disabled={claimingNFT ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              getMintToAddressBatch();
            }}>
            {claimingNFT ? "MINTING NYMPH" : `SUMMON ${mintNumber} DIVINE NYMPH${mintNumber > 1 ? 'S' : ''} TO THE BATCH ADDRESS`} 
          </button>
          <button className="mint-number-button" onClick={(e) => getNewMintNumber(mintNumber, '+')}> + </button>
        </div>
      </div>

      <form>
        <label>BATCH _to mint to an address ADMIN</label>
        {/* <input type="text" name="minttoaddy" id="addz" placeholder="&nbsp _to (address)"></input> */}
      </form>


      <form>
        <label>give this addresss a coupon</label>
        <input type="text" name="couponname" id="f" placeholder="coupon name"></input>
        <input type="text" name="couponadd" id="g" placeholder="coupon address"></input>

        <button
          disabled={AdminsOpping ? 1 : 0}
          onClick={(e) => {
          e.preventDefault();
          giveaCoupon();
          }}
          >
            {AdminsOpping ? "MM OK  IF YOU SAY SO!" : "[done] COUPON TO THAT ADDRESS ABOVE "} 
        </button>
      </form>

      <form>
        <label>delete this coupon</label>
        <input type="text" name="deletecoupon" id="x" placeholder="coupon to delete"></input>

        <button
          disabled={AdminsOpping ? 1 : 0}
          onClick={(e) => {
          e.preventDefault();
          DiscountBomb()

          }}
          >
          {AdminsOpping ? "MM OK  IF YOU SAY SO!" : "[done] delete the above coupon "} 
        </ button>
      </form>
          
          
      <form>
        <label>create a coupon</label>
        <input type="text" name="coupperc" id="6" placeholder="coupon percentage"></input>
        <input type="text" name="coupname" id="9" placeholder="coupon name">
        </input>
      </form>

      <button
        disabled={AdminsOpping ? 1 : 0}
        onClick={(e) => {
        newDiscount();
        }}
        >
        {AdminsOpping ? "MM OK  IF YOU SAY SO!" : "[done] CREATE A COUPON "} 
      </button>

      <img src={adminzone} alt="loading..." />
      <img src={adminzone2} alt="loading..." />
      <img src={adminzone3} alt="loading..." />
      <img src={security} alt="loading..." />
      
      Lazarus, Head of Security @ Divine Nymphs

      <button
          disabled={AdminsOpping ? 1 : 0}
        onClick={(e) => {
          e.preventDefault();
          MintSwitch(0);

        }}
      >
        {AdminsOpping ? "HANG ON " : "[done] SWITCH THE MINTER ON/OFF "} 
      </button>

      <button
          disabled={AdminsOpping ? 1 : 0}
          onClick={(e) => {
          e.preventDefault();
          RoyaltySwitch(0);

        }}
      >
        {AdminsOpping ? "MM OK  IF YOU SAY SO" : "[done] SWITCH THE ROYALTIES ON/OFF  (BE CAREFUL!!!)"} 
      </button>

      <button
          disabled={AdminsOpping ? 1 : 0}
          onClick={(e) => {
          e.preventDefault();
          DiscountSwitch(0);

        }}
      >
        {AdminsOpping ? "MM OK  IF YOU SAY SO" : "[done] SWITCH THE DISCOUNTS ON/OFF  (BE CAREFUL!!!)"} 
      </button>

      <button
        disabled={AdminsOpping ? 1 : 0}
        onClick={(e) => {
        PullItAll();

        }}
      >  
        {AdminsOpping ? "MM OK  IF YOU SAY SO!" : "[done] WITHDRAWAL ALL MONEY FROM THE CONTRACT (to admin wallet) "} 
      </button>

      <form>
        <label>new BASE URI </label>
        <input type="text" name="assmonster" id="am" placeholder="new base URI">
        </input>

        <button
          disabled={AdminsOpping ? 1 : 0}
          onClick={(e) => {
          console.log('nb')
          }}
          >
            {AdminsOpping ? "MM OK  IF YOU SAY SO!" : "set new BAse URI "} 
        </button>
      </form>

      <form>
        <label>change royalty  </label>
        <input type="text" name="tokenID" id="tid" placeholder="token id"></input>
        <input type="text" name="receiver" id="rr" placeholder="royalty receiver"></input>
        <input type="text" name="newroyalty" id="nr" placeholder="new royalty? fuck it"></input>

        <button
          disabled={AdminsOpping ? 1 : 0}
          onClick={(e) => {
          e.preventDefault();
          RoyaltyChange('nr');
          }}
          >
          {AdminsOpping ? "MM OK  IF YOU SAY SO!" : "[done] set the new royalty chief "} 
        </button>
      </form>

      <form>
        <label>new WALLET to receive ETH</label>
        <input type="text" name="newwallettt" id="nw" placeholder="new wallet that receives the dough"></input>
        <button
          disabled={AdminsOpping ? 1 : 0}
          onClick={(e) => {
          e.preventDefault();
          NewWallet();
          }}
        >
          {AdminsOpping ? "MM OK  IF YOU SAY SO!" : " [done] set new wallet"} 
        </button>
      </form>

      <form>
        <label>new listing price (set in wei) </label>
        <input type="text" name="newprice" id="np" placeholder="new price"></input>

        <button
          disabled={AdminsOpping ? 1 : 0}
          onClick={(e) => {
          e.preventDefault();
          newListingPrice('np');
          }}
          >
            {AdminsOpping ? "MM OK  IF YOU SAY SO!" : "[done]  set new listing price (dont)  "} 
          </button>
        </form>

        <form>
          <label> transfer contract balance </label>
          <button
            disabled={AdminsOpping ? 1 : 0}
            onClick={(e) => {
            e.preventDefault();
            RipItBaby();
            }}
          >
            {AdminsOpping ? "MM OK  IF YOU SAY SO!" : " [done] transfer balance  "} 
          </button>
        </form>

        <form>
          <label> new base URI </label>
          <input type="text" name="nburi" id='nbz' placeholder="new baseurison"></input>
            <button
              disabled={AdminsOpping ? 1 : 0}
              onClick={(e) => {
              e.preventDefault();
              givenewbaseURI('nbz');
              }}
            >
              {AdminsOpping ? "MM OK  IF YOU SAY SO!" : " [done] new base uri  "} 
            </button>
        </form>
      </AdminStyles>
  )
}